[data-page-element="Survey/V1"] {
      padding-left: 12px;
padding-right: 12px;
    }
      [data-page-element="Survey/V1"] {
      --container-font-family: var(--style-guide-font-family-content);
--question-font-size: 32px;
--question-color: var(--style-color, #333);
--answer-font-size: 20px;
--answer-color: var(--style-color, #333);
--answer-active-color: var(--style-link-color, #0e8dc7);
--answer-hover-color: #fff;
--answer-hover-bg-color: var(--style-link-color, #0e8dc7);
--progress-bg-color: var(--style-link-color, #0e8dc7);
--progress-bg-incomplete-color: #ddd;
--progress-corners: 4px;
--button-bg-color: var(--style-link-color, #0e8dc7);
--button-bg-color-hover: color-mix(in srgb, var(--button-bg-color) 80%, transparent);
--button-bg-color-active: color-mix(in srgb, var(--button-bg-color) 60%, transparent);
--button-color: #fff;
--button-color-hover: #fff;
--button-color-active: #fff;
--button-font-size: 20px;
--input-font-size: 16px;
--input-color: var(--style-color, #333);
--input-bg-color: #fff;
--input-border-color: #C0BFCF;
--input-corners: 4px;
padding-bottom: 12px;
padding-top: 12px;
font-family: var(--container-font-family);
min-height: 200px;
position: relative;
    }
[data-page-element="Survey/V1"] .elButton {
      border-style: none;
border-radius: 6px;
    }
[data-page-element="Survey/V1"] .elButton.elButton {
      width: 100%;
background: var(--button-bg-color);
    }
      [data-page-element="Survey/V1"] .elButton.elButton .elButtonText {
      font-weight: bold;
font-size: var(--button-font-size);
color: var(--button-color);
    }
[data-page-element="Survey/V1"] .elButton.elButton .elButtonSub {
      opacity: 1;
color: var(--button-subtext-color);
    }
      
    
[data-page-element="Survey/V1"] .elButton.elButton:hover,
[data-page-element="Survey/V1"] 
.elButton.elButton.elButtonHovered {
      background: var(--button-bg-color-hover);
    }
      [data-page-element="Survey/V1"] .elButton.elButton:hover .elButtonText,
[data-page-element="Survey/V1"] 
.elButton.elButton.elButtonHovered .elButtonText {
      color: var(--button-color-hover);
    }
[data-page-element="Survey/V1"] .elButton.elButton:hover .elButtonSub,
[data-page-element="Survey/V1"] 
.elButton.elButton.elButtonHovered .elButtonSub {
      color: var(--button-subtext-color-hover);
    }
      
    
[data-page-element="Survey/V1"] .elButton.elButton:active,
[data-page-element="Survey/V1"] 
.elButton.elButton.elButtonActive {
      background: var(--button-bg-color-active);
    }
      [data-page-element="Survey/V1"] .elButton.elButton:active .elButtonText,
[data-page-element="Survey/V1"] 
.elButton.elButton.elButtonActive .elButtonText {
      color: var(--button-color-active);
    }
[data-page-element="Survey/V1"] .elButton.elButton:active .elButtonSub,
[data-page-element="Survey/V1"] 
.elButton.elButton.elButtonActive .elButtonSub {
      color: var(--button-subtext-color-active);
    }
      
    
[data-page-element="Survey/V1"] .elSurveyQuestionTitle .elHeadline {
      text-align: center;
font-size: var(--question-font-size);
color: var(--question-color);
font-weight: 600;
    }
[data-page-element="Survey/V1"] elSurveyQuestionDescription .elSubheadline {
      text-align: center;
font-size: var(--question-font-size);
color: var(--answer-color);
font-weight: 500;
    }
[data-page-element="Survey/V1"] .elSurveyFieldLabel .elHeadline {
      text-align: center;
font-size: var(--question-font-size);
color: var(--question-color);
font-weight: 600;
    }
[data-page-element="Survey/V1"] .elSurveyFieldDescription .elSubheadline {
      text-align: center;
color: var(--question-color);
font-weight: 500;
    }
[data-page-element="Survey/V1"] .elSurveyCollection .elHeadline {
      text-align: left;
font-weight: 500;
    }
[data-page-element="Survey/V1"] .elSurveyCollection .elSubheadline {
      text-align: left;
font-weight: 500;
    }
[data-page-element="Survey/V1"] .elSurveyQuestion {
      width: 100%;
    }
[data-page-element="Survey/V1"] .elSurveyList .elSurveyItem {
      width: 100%;
    }
[data-page-element="Survey/V1"] .elSurveyList {
      padding: 15px 10px;
    }
[data-page-element="Survey/V1"] form {
      display: flex;
gap: 20px;
flex-direction: column;
    }
[data-page-element="Survey/V1"] [data-page-element="Checkbox/V1"] .elCheckboxLabel .elCheckboxInput:checked ~ .elCheckbox {
      background-color: var(--answer-active-color);
    }
[data-page-element="Survey/V1"] .elCheckboxText,
[data-page-element="Survey/V1"] .elRadioText {
      font-size: var(--answer-font-size);
color: var(--answer-color);
    }
[data-page-element="Survey/V1"] .elCheckboxWrapper,
[data-page-element="Survey/V1"] .elRadioWrapper {
      cursor: pointer;
    }
[data-page-element="Survey/V1"] .elCheckboxWrapper:hover .elCheckboxText,
[data-page-element="Survey/V1"] .elRadioWrapper:hover .elRadioText {
      color: var(--answer-hover-color);
    }
[data-page-element="Survey/V1"] .elCheckboxWrapper:hover,
[data-page-element="Survey/V1"] .elRadioWrapper:hover {
      border-radius: 4px;
background-color: var(--answer-hover-bg-color);
    }
[data-page-element="Survey/V1"] .elSurveyItem .elRadioWrapper,
[data-page-element="Survey/V1"] .elSurveyItem .elCheckboxWrapper {
      padding-left: 10px;
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px;
    }
[data-page-element="Survey/V1"] .elCheckbox.elCheckbox,
[data-page-element="Survey/V1"] .elRadio.elRadio {
      max-width: 20px;
max-height: 20px;
min-width: 20px;
min-height: 20px;
border-color: var(--answer-active-color);
    }
[data-page-element="Survey/V1"] .elRadioLabel .elRadioInput:checked ~ .elRadio {
      border-color: var(--answer-active-color);
    }
[data-page-element="Survey/V1"] .elRadioIcon.elRadioIcon {
      width: 10px;
height: 10px;
background-color: var(--answer-active-color);
    }
[data-page-element="Survey/V1"] .elRadioLabel {
      display: flex;
align-items: center;
    }
[data-page-element="Survey/V1"] .elRadioLabel .elRadio {
      flex-grow: 0;
margin-top: 0;
margin-bottom: 0;
margin-left: 0;
margin-right: 0;
    }
[data-page-element="Survey/V1"] .elRadioLabel .elRadioText {
      margin-left: 8px;
    }
[data-page-element="Survey/V1"] .elOptionGrid {
      display: grid;
width: 100%;
grid-template-columns: 100%;
grid-template-rows: auto;
grid-auto-flow: row;
-moz-column-gap: 16px;
     column-gap: 16px;
row-gap: 16px;
justify-items: stretch;
align-items: stretch;
    }
[data-page-element="Survey/V1"] .elSurveyButtonRightAligned {
      margin-left: auto;
    }
[data-page-element="Survey/V1"] .elHide {
      display: none;
    }
[data-page-element="Survey/V1"] .borderHolder {
      background-color: var(--input-bg-color);
    }
[data-page-element="Survey/V1"] .elInput {
      font-size: var(--input-font-size);
color: var(--input-color);
    }
[data-page-element="Survey/V1"] .inputHolder,
[data-page-element="Survey/V1"] .borderHolder {
      border-width: 1px;
border-color: var(--input-border-color) !important;
border-style: solid;
padding-top: 12px;
padding-bottom: 12px;
border-radius: var(--input-corners);
padding-left: 12px;
padding-right: 12px;
    }
[data-page-element="Survey/V1"] .inputHolder {
      border-color: transparent !important;
    }
[data-page-element="Survey/V1"] .elInputError .borderHolder.borderHolder.borderHolder {
      border-color: red;
border-width: var(--input-border-width);
    }
[data-page-element="Survey/V1"] [data-error-container][data-error-container][data-error-container]:not(:empty) {
      position: relative !important;
color: red;
z-index: 1;
font-size: 11px;
margin-bottom: -8px;
margin-top: 4px;
    }
[data-page-element="Survey/V1"] .elError:empty {
      display: none;
    }
[data-page-element="Survey/V1"] .elError {
      color: red;
display: block;
    }
[data-page-element="Survey/V1"] .labelOnBorder.elInputFocused .labelText,
[data-page-element="Survey/V1"] .labelOnBorder.hasValue .labelText {
      border-top-color: var(--input-border-color) !important;
    }
[data-page-element="Survey/V1"] [data-page-element="ProgressBar/V1"] {
      margin: 12px 0px;
    }
[data-page-element="Survey/V1"] [data-page-element="ProgressBar/V1"] .progress {
      border-radius: var(--progress-corners);
height: 10px;
background-color: var(--progress-bg-incomplete-color);
    }
[data-page-element="Survey/V1"] [data-page-element="ProgressBar/V1"] .progress-bar {
      background: var(--progress-bg-color);
    }
[data-page-element="Survey/V1"] .elSpinnerWrapper {
      min-height: 200px;
background-color: transparent;
--spinner-rotating-color: #000;
--spinner-still-color: 0, 0, 0;
    }
[data-page-element="Survey/V1"] .elSpinner {
      font-size: 10px;
    }
[data-page-element="Survey/V1"] .elSurveyButtonWrapper {
      width: auto;
display: inline-block;
float: right;
position: relative;
    }
[data-page-element="Survey/V1"] .elSurveyButtonNext[data-loading="true"] .elButtonMainText {
      visibility: hidden;
    }
[data-page-element="Survey/V1"] .elSurveyButtonLoading {
      display: none;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -20%);
color: var(--button-color);
    }
[data-page-element="Survey/V1"] .elSurveyButtonNext[data-loading="true"] ~ .elSurveyButtonLoading {
      display: block;
    }
[data-page-element="Survey/V1"] .elSurveyOtherFocused *:hover {
      background-color: var(--input-bg-color) !important;
color: var(--answer-color) !important;
cursor: text;
    }
[data-page-element="Survey/V1"] .elSurveyOtherFocused input {
      border: none;
border-bottom: 1px solid var(--answer-active-color);
margin-left: 8px;
font-size: var(--answer-font-size);
width: 50%;
    }
[data-page-element="Survey/V1"] .otherOptionProvided {
      width: 50%;
border-bottom: 1px solid var(--answer-active-color);
    }
[data-page-element="Survey/V1"] .iti input {
      z-index: 1;
    }
[data-page-element="Survey/V1"] .iti__flag-container {
      z-index: 10;
    }
[data-page-element="Survey/V1"] .iti {
      width: 100%;
    }
[data-page-element="Survey/V1"] .iti ~ .elLabel .labelText {
      padding-left: 50px;
    }
[data-page-element="Survey/V1"] .iti__flag-container.iti__flag-container.iti__flag-container {
      opacity: 1;
    }
      @media (min-width: 770px) {
        
      [data-page-element="Survey/V1"] .elOptionGrid[data-option-image-size="medium"] {
      grid-template-columns: 1fr 1fr;
    }
[data-page-element="Survey/V1"] .elOptionGrid[data-option-image-size="small"] {
      grid-template-columns: 1fr 1fr;
    }
      
    
      }
@media (min-width: 1000px) {
        
      [data-page-element="Survey/V1"] .elOptionGrid[data-option-image-size="small"] {
      grid-template-columns: 1fr 1fr 1fr;
    }
      
    
      }
    